@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

body {
  font-family: "Montserrat", sans-serif;
}

@page numberedPage {
  @bottom-right {
    content: "Hal " counter(page) "/" counter(pages);
  }
}

.print-with-pagination {
  page: numberedPage;
}

@media print {
  .page-break {
    /* clear: both; */
    page-break-after: always;
  }

  .keep-together {
    page-break-inside: avoid;
  }
}

.hidden-print {
  display: none;
}

@media print {
  .hidden-print {
    display: block;
  }

  @page {
    size: a4;
    margin: 10mm;
  }
}

/* SCROLLBAR STYLING */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007af1;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0162c3;
}

/* REMOVE STEPPER NUMBER */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
